import React, { useEffect, useState } from "react";
import Navbar from "../../common/Navbar";
import Footer from "../../common/Footer";
import { checkAuth, authenticateSelector } from "../../api/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createOrder } from "../../api/order";
import { orderSelector } from "../../api/order";
import { cartProductSelector } from "../../api/cartSlice";
import AddCoupon from "./addcoupon";
import { couponSelector } from "../../api/coupon";
import { toast } from "react-toastify";
import axios from "axios";
import { keyUri } from "../../key";
import { nanoid } from "nanoid";

function Orderdetails() {
  const dispatch = useDispatch();
  const nav = useNavigate();

  const { cartProducts, total } = useSelector(cartProductSelector);
  const { shippingcharge } = useSelector(orderSelector);
  const { user } = useSelector(authenticateSelector);
  const { coupons } = useSelector(couponSelector);
  const [selectedOption, setSelectedOption] = useState("payOnline");

  const totalamount = total + shippingcharge?.shippingAmount;

  useEffect(() => {
    dispatch(checkAuth());
  }, []);

  const [formData, setFormData] = useState({
    delivred_username: "",
    delivred_email: "",
    delivred_district: "",
    delivred_pincode: "",
    delivred_addrees: "",
    delivred_phonenumber: "",
    deliverd_town: "",
    delivred_state: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if cartProducts is empty
    if (cartProducts.length === 0) {
      toast.success("Your Bag is empty", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const orderData = {
      orderProducts: cartProducts,
      user: user?._id,
      address: user?.address,
      status: "unpaid",
      payment: selectedOption,
      total: coupons?.amount ? coupons.amount : totalamount,
      sub_total: total,
      coupons: coupons?.coupon ? coupons._id : null,
      delivred_state: shippingcharge?.shipping,
      deliverd_date: shippingcharge?.deliveryDate,
      delivred_username: formData?.delivred_username,
      delivred_district: formData?.delivred_district,
      delivred_pincode: formData?.delivred_pincode,
      delivred_addrees: formData?.delivred_addrees,
      delivred_phonenumber: formData?.delivred_phonenumber,
      deliverd_town: formData?.deliverd_town,
      delivred_state: formData?.delivred_state,
      delivred_email: formData?.delivred_email,
    };

    dispatch(createOrder(orderData));
  };

  const handleOptionChange1 = (event) => {
    setSelectedOption(event.target.value);
  };

  // const handlePayOnline = async (e) => {
  //   e.preventDefault();
  //   if (selectedOption === "payOnline") {

  //     if (Object.values(formData).some((value) => value === "")) {
  //       alert("Please fill in all address fields to pay online");
  //       return;
  //     }

  //     try {
  //       const orderData = {
  //         orderProducts: cartProducts,
  //         user: user?._id,
  //         address: user?.address,
  //         status: "unpaid",
  //         payment: selectedOption,
  //         total: coupons?.amount ? coupons.amount : totalamount,
  //         sub_total: total,
  //         coupons: coupons?.coupon ? coupons._id : null,
  //         delivred_state: shippingcharge?.shipping,
  //         deliverd_date: shippingcharge?.deliveryDate,
  //         delivred_username: formData?.delivred_username,
  //         delivred_district: formData?.delivred_district,
  //         delivred_pincode: formData?.delivred_pincode,
  //         delivred_addrees: formData?.delivred_addrees,
  //         delivred_phonenumber: formData?.delivred_phonenumber,
  //         delivred_town: formData?.delivred_town,
  //         delivred_state: formData?.delivred_state,
  //       };

  //       localStorage.setItem("orderData", JSON.stringify(orderData));
  //       const response = await axios.post(keyUri.BACKEND_URI + '/payment', { ...orderData });

  //       console.log(response.data);

  //       const paymentUrl = response.data.redirectUrl;

  //       window.location.href = paymentUrl;

  //       if (response.data.success) {

  //         dispatch(createOrder(orderData));
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  // };

  // const handlePayOnline = async (e) => {
  //   e.preventDefault();
  //   if (selectedOption === "payOnline") {

  //     if (Object.values(formData).some((value) => value === "")) {
  //       alert("Please fill in all address fields to pay online");
  //       return;
  //     }

  //     try {
  //       const orderData = {
  //         orderProducts: cartProducts,
  //         user: user?._id,
  //         address: user?.address,
  //         status: "unpaid",
  //         payment: selectedOption,
  //         total: coupons?.amount ? coupons.amount : totalamount,
  //         sub_total: total,
  //         coupons: coupons?.coupon ? coupons._id : null,
  //         delivred_state: shippingcharge?.shipping,
  //         deliverd_date: shippingcharge?.deliveryDate,
  //         delivred_username: formData?.delivred_username,
  //         delivred_district: formData?.delivred_district,
  //         delivred_pincode: formData?.delivred_pincode,
  //         delivred_addrees: formData?.delivred_addrees,
  //         delivred_phonenumber: formData?.delivred_phonenumber,
  //         delivred_town: formData?.delivred_town,
  //         delivred_state: formData?.delivred_state,
  //       };

  //       localStorage.setItem("orderData", JSON.stringify(orderData));
  //       const response = await axios.post(keyUri.BACKEND_URI + '/payment', { ...orderData });

  //       console.log(response.data);

  //       const paymentUrl = response.data.redirectUrl;

  //       window.location.href = paymentUrl;

  //       if (response.data.success) {

  //         dispatch(createOrder(orderData));
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  // };

  const handlePayOnline = async (e) => {
    e.preventDefault();
    if (selectedOption === "payOnline") {
      if (Object.values(formData).some((value) => value === "")) {
        alert("Please fill in all address fields to pay online");
        return;
      }
      // const API_URL = keyUri.BACKEND_URI + `/razorpayp/`
      // const orderUrl = `${API_URL}orderp`;
      const orderData = {
        orderProducts: cartProducts,
        user: user?._id,

        address: user?.address,
        status: "Paid",
        payment: selectedOption,
        total: coupons?.amount ? coupons.amount : totalamount,
        sub_total: total,
        coupons: coupons?.coupon ? coupons._id : null,
        delivred_state: shippingcharge?.shipping,
        deliverd_date: shippingcharge?.deliveryDate,
        delivred_username: formData?.delivred_username,
        delivred_district: formData?.delivred_district,
        delivred_pincode: formData?.delivred_pincode,
        delivred_addrees: formData?.delivred_addrees,
        delivred_phonenumber: formData?.delivred_phonenumber,
        deliverd_town: formData?.deliverd_town,
        delivred_state: formData?.delivred_state,
        delivred_email: formData?.delivred_email,
      };
      const response = await axios.post(
        keyUri.BACKEND_URI + `/product-order/`,
        orderData
      );
      const { data } = response;
      console.log("App -> razorPayPaymentHandler -> data", data);

      const options = {
        key: "rzp_live_zC9UbXcHgcG7eb",
        name: "Alpxel",
        order_id: data.id,
        handler: async (response) => {
          try {
            const paymentId = response.razorpay_payment_id;
            const url = keyUri.BACKEND_URI + `/capture-product/${paymentId}`;
            const captureResponse = await axios.post(url, { total });
            console.log({ captureResponse });
            const successObj = JSON.parse(captureResponse.data);
            const captured = successObj.captured;
            console.log(
              "App -> razorPayPaymentHandler -> captured",
              successObj
            );

            //  if(captured){
            //      console.log('success')

            //     await axios.post(keyUri.BACKEND_URI + '/orderp', {user,title, info, total, successObj, productId

            //     }, config)
            //  }

            // toast.success("Payment success!", { autoClose: 2000 });
            dispatch(createOrder(orderData));
          } catch (err) {
            console.log(err);
          }
        },
        prefill: {
          name: orderData?.formData?.delivred_username,
          // email: user.email,
          contact: orderData?.formData?.delivred_phonenumber,
        },
        theme: {
          color: "#314387",
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    }
  };

  const handlePlaceOrder = async () => {
    if (selectedOption === "cashOnDelivery") {
      if (Object.values(formData).some((value) => value === "")) {
        alert("Please fill in all address fields.");
        return;
      }
    }

    const orderData = {
      orderProducts: cartProducts,
      user: user?._id,
      address: user?.address,
      status: "unpaid",
      payment: selectedOption,
      total: coupons?.amount ? coupons.amount : totalamount,
      sub_total: total,
      coupons: coupons?.coupon ? coupons._id : null,
      delivred_state: shippingcharge?.shipping,
      deliverd_date: shippingcharge?.deliveryDate,
      delivred_username: formData?.delivred_username,
      delivred_district: formData?.delivred_district,
      delivred_pincode: formData?.delivred_pincode,
      delivred_addrees: formData?.delivred_addrees,
      delivred_phonenumber: formData?.delivred_phonenumber,
      deliverd_town: formData?.deliverd_town,
      delivred_state: formData?.delivred_state,
      delivred_email: formData?.delivred_email,
    };

    dispatch(createOrder(orderData));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish1 = (values) => {
    // Handle coupon submission here, e.g., validate and apply the coupon code.
    console.log("Coupon Code:", values.delivered_pincode);
  };

  return (
    <div>
      <Navbar />
      <section class="pt-4  mx-8 md:mx-20">
        <div class="container  mx-auto">
          <div class="relative pb-4 md:pb-9 ">
            <h2 class="text-2xl md:text-4xl text-[#314387] font-bold text-start md:text-center  font-heading ">
              Shipping Details
            </h2>
            <div class="absolute left-1/2 transform -translate-x-1/2 bottom-0 w-screen border-b border-black border-opacity-5"></div>
          </div>
          <form
            name="nest-messages"
            // Call handleSubmit when the form is submitted
            initialValues={{ remember: true }}
            onFinishFailed={onFinishFailed}
            className="mt-4"
            style={{ textAlign: "start" }}
          >
            <div class="flex flex-wrap justify-end w-full lg:justify-center md:gap-6 -mx-4 pb-6 mb-6 xl:pb-10 xl:mb-10 border-b border-black border-opacity-10">
              <div className="w-[600px]">
                <div class="flex flex-wrap gap-2 -mx-4">
                  <div class="w-full md:w-1/2 px-8 mb-2">
                    <div class="lg:max-w-sm">
                      <div class="mb-2">
                        <label class="block mb-1 text-base md:text-lg text-gray-700 text-darkBlueGray-400">
                          Your name:
                        </label>
                        <input
                          class="w-full px-5 text-sm   leading-9  border-2 border-[#F15926] outline-none focus:ring-2 focus:ring-[#F15926] focus:ring-opacity-50 rounded-xl"
                          type="text"
                          required={true}
                          id="exampleFormControlInput2"
                          name="delivred_username"
                          onChange={handleChange}
                          value={formData?.delivred_username}
                        />
                      </div>
                      <div class="mb-2">
                        <label class="block mb-1 text-base text-gray-700 md:text-lg text-darkBlueGray-400">
                          Phone Number:
                        </label>
                        <input
                          class="w-full px-5 text-sm   leading-9  border-2 border-[#F15926] outline-none focus:ring-2 focus:ring-[#F15926] focus:ring-opacity-50 rounded-xl"
                          type="number"
                          required={true}
                          id="exampleFormControlInput2"
                          name="delivred_phonenumber"
                          onChange={handleChange}
                          value={formData?.delivred_phonenumber}
                        />
                      </div>

                      <div class="mb-2">
                        <label class="block mb-1 text-base text-gray-700 md:text-lg text-darkBlueGray-400">
                          Email:
                        </label>
                        <input
                          class="w-full px-5 text-sm   leading-9  border-2 border-[#F15926] outline-none focus:ring-2 focus:ring-[#F15926] focus:ring-opacity-50 rounded-xl"
                          type="email"
                          required={true}
                          id="exampleFormControlInput2"
                          name="delivred_email"
                          onChange={handleChange}
                          value={formData?.delivred_email}
                        />
                      </div>

                      <div class="mb-2">
                        <label class="block mb-1 text-base text-gray-700 md:text-lg text-darkBlueGray-400">
                          Address 1:
                        </label>
                        <input
                          class="w-full px-5 text-sm   leading-9  border-2 border-[#F15926] outline-none focus:ring-2 focus:ring-[#F15926] focus:ring-opacity-50 rounded-xl"
                          type="text"
                          required={true}
                          id="exampleFormControlInput2"
                          name="delivred_addrees"
                          onChange={handleChange}
                          value={formData?.delivred_addrees}
                        />
                      </div>
                      <div>
                        <label class="block mb-1 text-base text-gray-700 md:text-lg text-darkBlueGray-400">
                          Address 2:
                        </label>
                        <input
                          class="w-full px-5 text-sm   leading-9  border-2 border-[#F15926] outline-none focus:ring-2 focus:ring-[#F15926] focus:ring-opacity-50 rounded-xl"
                          type="text"
                          required={true}
                          id="exampleFormControlInput2"
                          name="deliverd_town"
                          onChange={handleChange}
                          value={formData?.deliverd_town}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 px-8">
                    <div class="lg:max-w-sm">
                      <div class="mb-2">
                        <label class="block mb-1 text-base text-gray-700 md:text-lg text-darkBlueGray-400">
                          District:
                        </label>
                        <input
                          class="w-full px-5 text-sm   leading-9  border-2 border-[#F15926] outline-none focus:ring-2 focus:ring-[#F15926] focus:ring-opacity-50 rounded-xl"
                          type="text"
                          required={true}
                          id="exampleFormControlInput2"
                          name="delivred_district"
                          onChange={handleChange}
                          value={formData?.delivred_district}
                        />
                      </div>
                      <div class="mb-2">
                        <label class="block mb-1 text-base text-gray-700 md:text-lg text-darkBlueGray-400">
                          Pincode:
                        </label>
                        <input
                          class="w-full px-5 text-sm   leading-9  border-2 border-[#F15926] outline-none focus:ring-2 focus:ring-[#F15926] focus:ring-opacity-50 rounded-xl"
                          type="text"
                          required={true}
                          id="exampleFormControlInput2"
                          name="delivred_pincode"
                          onChange={handleChange}
                          value={formData?.delivred_pincode}
                        />
                      </div>
                      <div class="mb-8">
                        <label class="block mb-1 text-base text-gray-700 md:text-lg text-darkBlueGray-400">
                          State:
                        </label>
                        <input
                          class="w-full px-5 text-sm   leading-9  border-2 border-[#F15926] outline-none focus:ring-2 focus:ring-[#F15926] focus:ring-opacity-50 rounded-xl"
                          type="text"
                          required={true}
                          id="exampleFormControlInput2"
                          name="delivred_state"
                          onChange={handleChange}
                          value={formData?.delivred_state}
                        />
                      </div>

                      <div className="mb-8 px-2">
                        {/* <label className="relative flex mb-1 items-center">
                          <input
                            type="radio"
                            name="paymentMethod"
                            value="cashOnDelivery"
                            checked={selectedOption === "cashOnDelivery"}
                            onChange={handleOptionChange1}
                          />

                          <span className="ml-2 text-sm text-darkBlueGray-400">
                            Cash on delivery
                          </span>
                        </label> */}
                        <label className="relative flex items-center hidden">
                          <input
                            type="radio"
                            name="paymentMethod"
                            required
                            value="payOnline"
                            defaultChecked
                            checked={selectedOption === "payOnline"}
                            onChange={handleOptionChange1}
                          />

                          <span className="ml-2 text-sm text-darkBlueGray-400">
                            Online pay
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="w-full  md:w-1/2 lg:w-4/12 xl:w-3/12 md:px-4">
                <div class="pt-2 pb-4 bg-gray-600 rounded-3xl shadow-md">
                  <div class="border-b border-opacity-20">
                    <div class="px-10 pb-7">
                      <h3 class="mb-5 text-2xl text-white font-heading font-medium">
                        Cart totals
                      </h3>
                      <p class="flex items-center justify-between leading-8 font-heading font-medium">
                        <span class="text-white text-opacity-70">Subtotal</span>
                        <span class="flex items-center text-xl text-white">
                          <span class="mr-3 text-base">Rs</span>
                          <span>{total}/-</span>
                        </span>
                      </p>
                      <p class="flex items-center justify-between leading-8 font-heading font-medium">
                        <span class="text-white text-opacity-70">Shipping</span>
                        <span class="flex items-center text-xl text-white">
                          <span class="mr-3 text-base">Rs</span>
                          <span>{shippingcharge?.shippingAmount}/-</span>
                        </span>
                      </p>
                      <p class="flex items-center justify-between leading-8 font-heading font-medium">
                        <span class="text-white text-opacity-70">Discount</span>
                        <span class="flex items-center text-xl text-white">
                          <span class="mr-3 text-base">Rs</span>
                          <span>{coupons?.discountAmount}/-</span>
                        </span>
                      </p>
                      {coupons?.amount && (
                        <h1 className="mb-3">
                          You have received {coupons?.coupon?.discount}% coupon
                          discount
                        </h1>
                      )}
                    </div>
                  </div>
                  <div class="px-10 pt-5 mb-7">
                    <div class="pb-5 border-b border-opacity-30">
                      <p class="flex items-center justify-between leading-8 font-heading font-medium">
                        <span class="text-white">Total</span>
                        <span class="flex items-center text-xl text-white">
                          <span class="mr-3 text-base">Rs</span>
                          <span>
                            {coupons?.amount ? coupons.amount : totalamount}/-
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="mx-4 mb-8">
                    {" "}
                    <AddCoupon
                      onFinish={onFinish1}
                      user={user}
                      total={totalamount}
                    />
                  </div>
                  <div class="px-6 mx-auto md:max-w-max">
                    {selectedOption === "cashOnDelivery" ? (
                      <button
                        className="block px-6 w-full py-2 text-xl leading-6 font-medium tracking-tighter font-heading text-center text-white bg-[#F15926] hover:bg-[#E54D24] focus:ring-2 focus:ring-[#F15926] focus:ring-opacity-50 rounded-full"
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          handlePlaceOrder();
                          // handleSubmit(e);
                        }}
                      >
                        Place order
                      </button>
                    ) : (
                      <button
                        className="block px-6 w-full py-2 text-xl leading-6 font-medium tracking-tighter font-heading text-center text-white bg-[#F15926] hover:bg-[#E54D24] focus:ring-2 focus:ring-[#F15926] focus:ring-opacity-50 rounded-full"
                        type="button"
                        onClick={handlePayOnline}
                      >
                        Pay now
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Orderdetails;
