

const  keyUri = {



    // BACKEND_URI:'http://localhost:5000/api'

    //  BACKEND_URI:"https://sea-lion-app-3xqkx.ondigitalocean.app/api"
     BACKEND_URI:"https://walrus-app-4793y.ondigitalocean.app/api"
}

let token = localStorage.getItem('token')
const config = {
    headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    }
  };

export  {keyUri, config }