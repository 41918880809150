import React, { useState, useEffect, useLayoutEffect } from "react";
import styled from "styled-components";
import { AiOutlineLogin, AiOutlineLogout } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { motion, AnimatePresence } from "framer-motion";
import { Link, NavLink, useParams } from "react-router-dom";
import Logo from "../../src/assets/alpxel logo-02.png";
import { logOut } from "../api/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllProducts, productSelector } from "../api/products";
import { cartProductSelector } from "../api/cartSlice";
import { FaShippingFast } from "react-icons/fa";

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dropabout, setDropabout] = useState(false);

  const { id } = useParams();
  useEffect(() => {
    id && setIsMenuOpen(false);
  }, [id]);

  const user = JSON.parse(localStorage.getItem("userinfo"));
  const isLoggedIn = !!user;
  const dispatch = useDispatch();
  const { cartProducts } = useSelector(cartProductSelector);

  const { allproducts } = useSelector(productSelector);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(fetchAllProducts());
  }, [dispatch]);
  return (
    <div className="sticky top-0  z-50 ">
      <section className="bg-gray-100">
        <div className=" bg-gray-100 sticky top-0 z-50 ">
          <section className="hidden md:block">
            {" "}
            <nav>
              <div className="  flex  items-center justify-between gap-4   px-10 bg-transparent ">
                <Link
                  className="inline-block text-2xl w-32 h-20 font-bold mt-2 mr-10"
                  to="/"
                >
                  <img src={Logo} alt="" />
                </Link>
                <ul className="hidden lg:flex items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <li className="mr-6">
                    <Link
                      className="inline-block font-bold text-gray-900"
                      to="/"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="mr-6">
                    <Link
                      className="inline-block font-bold text-gray-900"
                      to="/aboutus"
                    >
                      About Us
                    </Link>
                  </li>

                  <li className="mr-6">
                    <button className="relative group inline-flex items-center text-left text-base font-bold text-gray-900">
                      <span className="mr-2">Products</span>
                      <svg
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.99999 5.08071C3.91243 5.08081 3.82572 5.06361 3.74483 5.03009C3.66394 4.99657 3.59048 4.94739 3.52866 4.88538L0.700184 2.05728C0.575173 1.93227 0.504943 1.76272 0.504944 1.58593C0.504944 1.40913 0.575175 1.23958 0.700187 1.11457C0.825199 0.989561 0.99475 0.919331 1.17154 0.919331C1.34834 0.919332 1.51789 0.989563 1.6429 1.11457L3.99999 3.47132L6.35708 1.11455C6.48209 0.989543 6.65165 0.919312 6.82844 0.919312C7.00523 0.919311 7.17478 0.989541 7.29979 1.11455C7.42481 1.23956 7.49504 1.40911 7.49504 1.58591C7.49504 1.7627 7.42481 1.93225 7.2998 2.05726L4.47132 4.8854C4.4095 4.9474 4.33604 4.99658 4.25515 5.0301C4.17426 5.06361 4.08755 5.08082 3.99999 5.08071V5.08071Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      <div className="hidden group-hover:block absolute z-50 bottom-0 left-0 transform translate-y-full w-56 pt-6">
                        <div className="p-2 bg-gray-100  rounded-md shadow">
                          {allproducts.map((item, index) => (
                            <Link
                              key={index}
                              to={`/individualpro/${item?._id}`}
                              className="block p-2 text-sm font-bold text-black hover:text-indigo-500"
                            >
                              {item?.product_name}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </button>
                  </li>

                  <li className="mr-6">
                    <button className="relative group inline-flex items-center text-left text-base font-bold text-gray-900">
                      <Link to="/allproducts">
                        <span className="mr-2">AllProducts</span>
                      </Link>
                    </button>
                  </li>

                  {user?.user_name && (
                    <li className="mr-6">
                      <Link
                        className="inline-block font-bold text-gray-900"
                        to="/userorder"
                      >
                        Your Orders
                      </Link>
                    </li>
                  )}

                  <li>
                    <Link
                      className="inline-block font-bold text-gray-900"
                      to="/Contactus"
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
                <div className="inline-flex items-center ">
                  <div className="hidden lg:flex  ">
                    {isLoggedIn ? (
                      <div className="flex justify-between gap-3">
                        <div className="flex items-center">
                          <CgProfile className="text-xl text-gray-800 mt-1 mr-2" />
                          <span className="text-md mt-1 text-gray-900 capitalize">
                            {user?.user_name}
                          </span>
                        </div>
                        <Link
                          to="/"
                          onClick={() => {
                            dispatch(logOut());
                          }}
                        >
                          <button className="bg-[#F15925] hover:bg-[#F15926]  text-white font-semibold py-1 px-4 rounded-full shadow-lg transition duration-300 ease-in-out">
                            Logout
                          </button>
                        </Link>
                      </div>
                    ) : (
                      <div>
                        <Link to="/login">
                          <button className="bg-[#F15925] hover:bg-[#F15926]  text-white mr-4 font-semibold py-1 px-4 rounded-full shadow-lg transition duration-300 ease-in-out">
                            Login
                          </button>
                        </Link>
                        {/* <Link to="/signup">
                          <button className="bg-[#F15925] hover:bg-[#F15926] text-white font-semibold py-1 px-4 rounded-full shadow-lg transition duration-300 ease-in-out">
                            Sign Up
                          </button>
                        </Link> */}
                      </div>
                    )}

                    <Link
                      className="ml-10 flex items-center justify-center text-[#F15925] hover:text-[#F15925] transition duration-200 relative"
                      to="/cart"
                    >
                      {cartProducts.length > 0 && (
                        <div className="badge text-end absolute -top-4 -right-4 bg-red-500 rounded-full w-6 h-6 flex items-center justify-center">
                          <p className="text-white text-sm">
                            {cartProducts.length}
                          </p>
                        </div>
                      )}
                      <svg
                        width="24"
                        height="24"
                        viewbox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.24 5.58H18.84L15.46 2.2C15.19 1.93 14.75 1.93 14.47 2.2C14.2 2.47 14.2 2.91 14.47 3.19L16.86 5.58H7.14L9.53 3.19C9.8 2.92 9.8 2.48 9.53 2.2C9.26 1.93 8.82 1.93 8.54 2.2L5.17 5.58H4.77C3.87 5.58 2 5.58 2 8.14C2 9.11 2.2 9.75 2.62 10.17C2.86 10.42 3.15 10.55 3.46 10.62C3.75 10.69 4.06 10.7 4.36 10.7H19.64C19.95 10.7 20.24 10.68 20.52 10.62C21.36 10.42 22 9.82 22 8.14C22 5.58 20.13 5.58 19.24 5.58Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M19.0499 12H4.86991C4.24991 12 3.77991 12.55 3.87991 13.16L4.71991 18.3C4.99991 20.02 5.74991 22 9.07991 22H14.6899C18.0599 22 18.6599 20.31 19.0199 18.42L20.0299 13.19C20.1499 12.57 19.6799 12 19.0499 12ZM10.6099 18.45C10.6099 18.84 10.2999 19.15 9.91991 19.15C9.52991 19.15 9.21991 18.84 9.21991 18.45V15.15C9.21991 14.77 9.52991 14.45 9.91991 14.45C10.2999 14.45 10.6099 14.77 10.6099 15.15V18.45ZM14.8899 18.45C14.8899 18.84 14.5799 19.15 14.1899 19.15C13.8099 19.15 13.4899 18.84 13.4899 18.45V15.15C13.4899 14.77 13.8099 14.45 14.1899 14.45C14.5799 14.45 14.8899 14.77 14.8899 15.15V18.45Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </Link>

                    {!isLoggedIn && (
                      <div className="ml-8 bg-[#314387] px-4 rounded-md">
                        <Link to="/guestOrder" className="flex gap-1">
                          <span className="text-lg font-bold text-white inline-block">
                            Track Order

                          </span> <span className="mt-2">
                          <FaShippingFast className="rounded-md text-white" />

                          </span>

                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </nav>
          </section>
        </div>

        {/* ..................................mobile............................................ */}

        <div className="flex items-center justify-between sticky top-0 z-50 md:hidden px-2">
          <button
            aria-label="Open Menu"
            title="Open Menu"
            class="p-2 -mr-1 transition duration-200 rounded focus:outline-none  focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
            onClick={() => setIsMenuOpen(true)}
          >
            <svg class="w-5 text-indigo-800" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
              />
              <path
                fill="currentColor"
                d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
              />
              <path
                fill="currentColor"
                d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
              />
            </svg>
          </button>

          <img class="h-16" src={Logo} alt="" width="auto" />

          <div class="lg:hidden fixed top-0 left-0">
            {isMenuOpen && (
              <SidebarWrap
                key="first"
                initial={{ x: -250, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -280, opacity: 1 }}
                transition={{
                  duration: 0.36,
                  type: "tween",
                  ease: "easeInOut",
                }}
              >
                <div class="absolute  w-full ">
                  <div className="container  lg:w-4/5 block">
                    <div>
                      <div className="flex gap-0 ">
                        <li class="dropdown inline ml-4  text-start  cursor-pointer">
                          <div class="dropdown-menu absolute hidden h-auto mt-3  z-50">
                            <ul class="block w-full bg-[#FFFF] shadow px-6 py-4 text-indigo-800 rounded"></ul>
                          </div>
                        </li>

                        <div className="flex items-center">
                          {user?.user_name && ( // Check if user_name is available
                            <CgProfile className="text-xl text-gray-800 mt-1 mr-2" />
                          )}
                          {user?.user_name && ( // Check if user_name is available
                            <span className="text-lg mt-1 font-semibold text-gray-900 capitalize">
                              {user?.user_name}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <MenuWrap>
                    <nav className="sticky top-0 z-50">
                      <ul class="space-y-2   text-left   text-[14px] text-indigo-800">
                        <li className="nav-item pl-2 font-bold ">
                          <NavLink
                            activeClassName="selected"
                            exact
                            to="/"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            Home{" "}
                          </NavLink>
                        </li>

                        <li className="nav-item pl-2 font-bold">
                          <NavLink
                            to="/aboutus"
                            onClick={() => setIsMenuOpen(false)}
                            activeClassName="selected"
                          >
                            About Us
                          </NavLink>
                        </li>
                        <li className="nav-item pl-2">
                          <div>
                            <li>
                              <Link
                                id="droptoggle"
                                to="#"
                                style={{
                                  backgroundColor: dropabout
                                    ? "#f5f5f5"
                                    : "#fff",
                                  color: dropabout
                                    ? "var(--brandColor)"
                                    : "text-indigo-900",
                                }}
                                onClick={() => setDropabout(!dropabout)}
                                exact
                              >
                                <b>Products</b>
                                {dropabout ? (
                                  <MdKeyboardArrowUp />
                                ) : (
                                  <MdKeyboardArrowDown />
                                )}
                              </Link>

                              {dropabout && (
                                <div className="dropdown_menu">
                                  <ul>
                                    {allproducts.map((item, index) => (
                                      <li>
                                        <Link
                                          key={index}
                                          to={`/individualpro/${item?._id}`}
                                          onClick={() => setIsMenuOpen(false)}
                                        ></Link>
                                        {item?.product_name}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            </li>
                          </div>
                        </li>

                        <li className="nav-item pl-2 font-bold">
                          <NavLink
                            to="/allproducts"
                            onClick={() => setIsMenuOpen(false)}
                            // activeClassName="selected"
                          >
                            All Products
                          </NavLink>
                        </li>

                        {user?.user_name && (
                          <li className="nav-item pl-2">
                            <NavLink to={`/userorder`}>My orders</NavLink>
                          </li>
                        )}
                        {/* <li className="nav-item pl-2 font-bold">
                          <NavLink
                            to="/gallery"
                            onClick={() => setIsMenuOpen(false)}
                            activeClassName="selected"
                          >
                            Gallery
                          </NavLink>
                        </li> */}
                        {/* <li className="nav-item pl-2 font-bold">
                          <NavLink
                            to="/returns"
                            onClick={() => setIsMenuOpen(false)}
                            activeClassName="selected"
                          >
                            Returns &amp; Refund
                          </NavLink>
                        </li>
                        <li className="nav-item pl-2 font-bold">
                          <NavLink
                            to="/privacy"
                            onClick={() => setIsMenuOpen(false)}
                            activeClassName="selected"
                          >
                            Privacy Policy
                          </NavLink>
                        </li>
                        <li className="nav-item pl-2 font-bold">
                          <NavLink
                            to="/terms"
                            onClick={() => setIsMenuOpen(false)}
                            activeClassName="selected"
                          >
                             Terms &amp; Conditions
                          </NavLink>
                        </li>
                        <li className="nav-item pl-2 font-bold">
                          <NavLink
                            to="/shipping"
                            onClick={() => setIsMenuOpen(false)}
                            activeClassName="selected"
                          >
                             Shipping &amp; Delivery
                          </NavLink>
                        </li> */}
                            <li className="nav-item pl-2 font-bold">
                          <NavLink
                            to="/guestOrder"
                            onClick={() => setIsMenuOpen(false)}
                            activeClassName="selected"
                          >
                            Track Order

                           <FaShippingFast className="rounded-md " />
                        
                          </NavLink>
                        </li>

                        <li className="nav-item pl-2 font-bold">
                          <NavLink
                            to="/contactus"
                            onClick={() => setIsMenuOpen(false)}
                            activeClassName="selected"
                          >
                            Contact Us
                          </NavLink>
                        </li>
                      </ul>
                      <div className="block md:hidden  text-start">
                        {" "}
                        <div className="inline-flex items-cente g">
                          <div className=" lg:flex gap-4 ">
                            {isLoggedIn ? (
                              <div className="flex justify-between gap-3">
                                <Link
                                  to="/"
                                  onClick={() => {
                                    dispatch(logOut());
                                  }}
                                >
                                  <button className="  font-semibold flex py-1 gap-2 px-4  transition duration-300 ease-in-out">
                                    Logout{" "}
                                    <AiOutlineLogout className=" font-semibold mt-1 " />
                                  </button>
                                </Link>
                              </div>
                            ) : (
                              <div>
                                <Link to="/login">
                                  <button className="  mr-4 font-semibold flex gap-2 py-1 px-4  transition duration-300 ease-in-out">
                                    Login{" "}
                                    <AiOutlineLogin className=" font-semibold mt-1 " />
                                  </button>
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </nav>
                  </MenuWrap>
                </div>
              </SidebarWrap>
            )}

            <div>
              <AnimatePresence wait>
                {isMenuOpen && (
                  <BackdropWrap
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                      duration: 0.36,
                      type: "tween",
                      ease: "easeInOut",
                    }}
                    onClick={() => setIsMenuOpen(false)}
                  />
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const Wrapper = styled.section`
  li > ul {
    transform: translatex(100%) scale(0);
  }
  li:hover > ul {
    transform: translatex(101%) scale(1);
  }
  li > button svg {
    transform: rotate(-90deg);
  }
  li:hover > button svg {
    transform: rotate(-270deg);
  }

  .group:hover .group-hover\:scale-100 {
    transform: scale(1);
  }
  .group:hover .group-hover\:-rotate-180 {
    transform: rotate(180deg);
  }
  .scale-0 {
    transform: scale(0);
  }
  .min-w-32 {
    min-width: 8rem;
  }
`;

const BackdropWrap = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 200vh;
  background-color: #0a0a0a05;
`;

const SidebarWrap = styled(motion.aside)`
  position: fixed;
  top: 0;
  left: 0;
  width: 75%;
  height: 100vh;
  background-color: #ffffff;
  z-index: 50;
`;

const MenuWrap = styled.div`
  .dropdown_menu {
    text-align: left;
    /* height: 167px ; */
    max-height: 167px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #f5f5f5;

    svg {
      font-size: 1rem;
      margin-right: 0.2rem;
    }
  }

  #subdroptoggle {
    background-color: white;
    font-size: 0.9rem;
    font-weight: 600;
    &:hover {
      background-color: #88448a;
      color: white;
    }
  }

  ul {
    padding-bottom: 0.7px;
    background-color: #f5f5f5;
    li {
      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
        margin-bottom: 0.1rem;
        transition: 0.3s;

        &:hover {
          background-color: #408080;
          color: white;
        }
      }

      list-style: none;
      background-color: white;
      /* color:; */
      cursor: pointer;

      svg {
        font-size: 1.3rem;
      }

      .selected {
        background-color: #88448a;
        color: black;
        width: 100%;
        display: block;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.5rem 0.75rem 0.5rem 0.9rem;
        margin-bottom: 0.3rem;
      }
    }
  }
`;
